import React from "react"
import { Navbar, Nav } from "react-bootstrap"

const Header = () => {
  return (
    <header>
      <Navbar style={{margin: "0 1.5em 0 1.5em"}}>
      <Navbar.Brand href="/">Priscilla Hamiter</Navbar.Brand>
      <Nav className="me-auto">
        <Nav.Item>
          <Nav.Link href="/artwork">Artwork</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/dataviz">Data Visualizations</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>Game Development</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/dynamic">Dynamic Website Builder</Nav.Link>
        </Nav.Item>
      </Nav>
      </Navbar>
    </header>
  )
}
export default Header
