/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"

import Header from "./header"


const Layout = ({ children }) => {
  const canvasRef = useRef();
  const [windowDimensions, setWindowDimensions] = useState({width: typeof window !== 'undefined' ? window.outerWidth : 1700, height: typeof window !== 'undefined' ? window.outerHeight: 1100})

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const points = []
    const interval = 70
    let attractive = 100
    
    const render = () => {
      
      context.clearRect(0, 0, windowDimensions.width, windowDimensions.height)
      context.fillStyle = '#fff'
      context.fillRect(0, 0, windowDimensions.width, windowDimensions.height)

      points.forEach((point) => {
        const squareRoot = Math.sqrt(Math.pow(Math.abs(ev.clientX - point.startX), 2) + Math.pow(Math.abs(ev.clientY - point.startY), 2))
        if (squareRoot < attractive) {
          gsap.killTweensOf(point)
          const xDirection = Math.sign(ev.clientX - point.startX)
          const yDirection = Math.sign(ev.clientY - point.startY)
          let forceX = Math.abs(ev.clientX - point.startX) / 6
          let forceY = Math.abs(ev.clientY - point.startY) / 6
    
          gsap.to(point, 0.5, {x: point.startX + forceX * xDirection, y: point.startY + forceY * yDirection, ease: "power2.easeInOut"})
        } else {
          if (!gsap.isTweening(point)) {
            gsap.to(point, 1, {x: point.startX, y: point.startY, ease: "power3.easeInOut", delay: 0.1})
          }
        }
        point.render(context)
      })
      
      window.requestAnimationFrame(render)
    }
    
    for(let x = interval; x < windowDimensions.width - interval; x += interval) {
      for(let y = interval; y < windowDimensions.height - interval; y += interval) {
        const point = new Point(x, y)
        points.push(point)
      } 
    }

    function handleResize() {
      setWindowDimensions({
        width: window.outerWidth,
        height: window.outerHeight
      })
    }
    
    window.addEventListener('mousedown', (ev) => {
      attractive = 350
    })
    
    window.addEventListener('mouseup', (ev) => {
      attractive = 150
    })
    
    window.addEventListener('mousemove', (event) => {
      ev.clientX = event.clientX 
      ev.clientY = event.clientY
      
    })

    window.addEventListener('resize', () => {
      handleResize()
      render()
    })

    render()
  });


  class Point {
    constructor (x, y, radius = 1) {
      this.startX = x
      this.startY = y
      this.x = x
      this.y = y
      this.fill = '#2d2d2d'
      this.radius = radius
    }
    render (ctx) {
      ctx.beginPath()
      ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI, false)
      ctx.fillStyle = this.fill
      ctx.lineWidth = 1
      ctx.strokeStyle = 'black'
      ctx.stroke()
      ctx.fill()
    }
  } 

  let ev = {
    clientX : 0,
    clientY: 0
  }

  return (
    <>
      <canvas width={windowDimensions.width} height={windowDimensions.height - 80} ref={canvasRef}></canvas>
      <Header />
      <div
        style={{
          margin: `0 5%`,
        }}
      >
        <main style={{ overflowY: "auto", overflowX: "hidden" }}>
          {children}
        </main>
        {/* <footer
          style={{
            marginTop: `2rem`,
            color: "gray",
          }}
        >
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  height: PropTypes.number
}

export default Layout
